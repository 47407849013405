<template>
  <v-container>
    <v-card :loading="loading" :disabled="loading">
      <h1 class="text-h5 font-weight-bold accent pa-3">
        <v-icon color="primary" left>mdi-domain</v-icon>
        Adicionar Empresa
      </h1>
      <v-divider></v-divider>
      <v-card-text class="pa-10">
        <v-form ref="form" v-model="validForm" lazy-validation>
          <v-row>
            <v-col cols="12" md="3">
              <!-- <h1 class="text-h6 font-weight-bold mb-4">Logo</h1> -->
              <v-card
                flat
                class="fill-height d-flex align-center justify-center"
              >
                <v-card flat v-if="logo">
                  <v-img :src="logo" contain></v-img>
                </v-card>
                <div v-else class="fill-height" style="width: 100%;">
                  <vue-dropzone
                    ref="myVueDropzone"
                    id="dropzone"
                    :class="{ hoverFile: hoverFile }"
                    :use-custom-dropzone-options="true"
                    :options="dropzoneOptions"
                    @vdropzone-file-added="fileAdded"
                    @vdropzone-drag-enter="hoverFile = true"
                    @vdropzone-drag-over="hoverFile = true"
                    @vdropzone-drag-leave="hoverFile = false"
                    :useCustomSlot="true"
                    :include-styling="false"
                  >
                    <div class="text-center">
                      <v-icon size="80" color="secondary">
                        mdi-image-plus
                      </v-icon>

                      <h3 class="text-subtitle-1 ">
                        Arrastar e soltar
                      </h3>
                      <div class="text-caption">
                        Clique para selecionar
                      </div>
                    </div>
                  </vue-dropzone>
                </div>
              </v-card>
            </v-col>

            <v-col cols="12" md="9" class="px-3">
              <h1 class="text-h6 font-weight-bold mb-3">Dados</h1>
              <v-row>
                <!--Razao Social -->
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Razão Social"
                    v-model="empresa.razao_social"
                    dense
                    :rules="formRules"
                    required
                  >
                  </v-text-field>
                </v-col>

                <!--Nome Fantasia -->
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Nome de Fantasia"
                    v-model="empresa.nome_fantasia"
                    dense
                    :rules="formRules"
                    required
                  >
                  </v-text-field>
                </v-col>

                <!--CNPJ -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-mask="`##.###.###/####-##`"
                    label="CNPJ"
                    v-model="empresa.cnpj"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <!--Inscricao Estadual -->
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Inscricao Estadual"
                    v-mask="`#########.##-##`"
                    v-model="empresa.inscricao_estadual"
                    dense
                  ></v-text-field>
                </v-col>

                <!--CPF -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-mask="`###.###.###-##`"
                    label="CPF"
                    v-model="empresa.cpf"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <!--RG -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-mask="`#######-#`"
                    label="RG"
                    v-model="empresa.rg"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <!--Ramo de Negocio -->
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Ramo de Negócio"
                    v-model="empresa.ramo_negocio"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <!--E-mail  -->
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="empresa.email"
                    label="E-mail"
                    dense
                  ></v-text-field>
                </v-col>

                <!-- telefone -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="empresa.telefone"
                    v-mask="`(##) ####-####`"
                    label="Telefone"
                    dense
                  ></v-text-field>
                </v-col>

                <!-- celular -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="empresa.celular"
                    v-mask="`(##) #.####-####`"
                    label="Celular"
                    dense
                    required
                    :rules="formRules"
                  ></v-text-field>
                </v-col>

                <!-- whatsapp -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="empresa.whatsapp"
                    v-mask="`(##) #.####-####`"
                    label="Whatsapp"
                    dense
                    required
                    :rules="formRules"
                  ></v-text-field>
                </v-col>

                <!--Telegram -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="empresa.telegram"
                    v-mask="`(##) #.####-####`"
                    label="Telegram"
                    dense
                    required
                    :rules="formRules"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12"><v-divider></v-divider></v-col>

            <!--Endereco -->
            <v-col cols="12" class="pa-3">
              <h1 class="text-h6 font-weight-bold mb-3">Endereço</h1>
              <v-row>
                <!-- CEP -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="cep"
                    v-mask="`#####-###`"
                    label="CEP"
                    dense
                    append-icon="mdi-magnify"
                    @keyup.enter="fetchCep"
                    @click:append="fetchCep"
                    :loading="loadingCep"
                  ></v-text-field>
                </v-col>

                <!--endereco -->
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Endereço"
                    v-model="empresa.endereco"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <!--('numero')- -->
                <v-col cols="12" md="3">
                  <v-text-field label="Número" v-model="empresa.numero" dense>
                  </v-text-field>
                </v-col>

                <!--('('bairro')-')- -->
                <v-col cols="12" md="3">
                  <v-text-field label="Bairro" v-model="empresa.bairro" dense>
                  </v-text-field>
                </v-col>

                <!--('('complemento')-')- -->
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Complemento"
                    v-model="empresa.complemento"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <!-- Cidade -->
                <v-col cols="12" md="3">
                  <v-text-field label="Cidade" v-model="empresa.cidade" dense>
                  </v-text-field>
                </v-col>

                <!-- UF -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="empresa.uf"
                    label="UF"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12"><v-divider></v-divider></v-col>

            <!--Contatos -->
            <v-col cols="12" class="pa-3">
              <h1 class="text-h6 font-weight-bold mb-3">Mídia</h1>
              <v-row>
                <!--Site -->
                <v-col cols="12" md="12">
                  <v-text-field label="Site" v-model="empresa.site" dense>
                  </v-text-field>
                </v-col>

                <!-- Instagram -->
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Instagram"
                    v-model="empresa.instagram"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <!-- Facebook -->
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Facebook"
                    v-model="empresa.facebook"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <!-- Linkedin -->
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Linkedin"
                    v-model="empresa.linkedin"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <!-- Youtube -->
                <v-col cols="12" md="3">
                  <v-text-field label="Youtube" v-model="empresa.youtube" dense>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="createEmpresa"
          :disabled="!validForm"
          color="secondary"
          class="white--text "
        >
          Adicionar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { inputRequired } from "@/helpers/utils.js";
import { postEmpresa } from "@/api/gestor/empresa.js";
import { buscaCep } from "@/api/geral/buscaCep.js";
import vue2Dropzone from "vue2-dropzone";

export default {
  name: "AdicionarEmpresa",

  data() {
    return {
      loading: false,
      empresa: {
        logo: "",
        nome_fantasia: "",
        razao_social: "",
        cpf: "",
        rg: "",
        cnpj: "",
        status: 1,
        inscricao_estadual: "",
        ramo_negocio: "",
        site: "",
        instagram: "",
        facebook: "",
        linkedin: "",
        youtube: "",
        endereco: "",
        numero: "",
        bairro: "",
        complemento: "",
        cep: "",
        uf: "",
        cidade: "",
        celular: "",
        whatsapp: "",
        telegram: "",
        email: "",
      },
      validForm: true,
      formRules: [inputRequired],
      hoverFile: false,
      logo: null,
      logoUpload: null,
      cep: "",
      loadingCep: false,
    };
  },

  components: {
    vueDropzone: vue2Dropzone,
  },

  watch: {},

  computed: {
    dropzoneOptions() {
      return {
        url: "/no-url",
        createfileThumbnails: false,
        acceptedFiles: ".png,.jpg,.jpeg",
        maxFilesize: 2,
        autoProcessQueue: false,
      };
    },
  },

  methods: {
    fetchCep() {
      if (this.cep && this.cep.length === 9) {
        this.empresa.cep = "";
        this.empresa.cidade = "";
        this.empresa.bairro = "";
        this.empresa.uf = "";
        this.empresa.endereco = "";

        this.loadingCep = true;
        buscaCep(this.cep)
          .then((response) => {
            this.empresa.cep = response.cep;
            this.empresa.cidade = response.city;
            this.empresa.bairro = response.neighborhood;
            this.empresa.uf = response.state;
            this.empresa.endereco = response.street;
          })
          .catch((error) => {
            if (error.response.status === 404) {
              this.$toast.error("CEP não encontrado!");
            }
          })
          .finally(() => {
            this.loadingCep = false;
          });
      }
    },

    fileAdded(file) {
      this.logoUpload = file;

      const vue = this;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        vue.logo = event.target.result;
      };
    },

    createEmpresa() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        const empresa = new FormData();
        if (this.logoUpload) {
          empresa.append("logo", this.logoUpload, this.logoUpload.name);
          empresa.append("logo64", this.logo);
        }
        empresa.append("razao_social", this.empresa.razao_social);
        empresa.append("nome_fantasia", this.empresa.nome_fantasia);
        empresa.append("status", this.empresa.status);
        empresa.append("cnpj", this.empresa.cnpj);
        empresa.append("cpf", this.empresa.cpf);
        empresa.append("inscricao_estadual", this.empresa.inscricao_estadual);
        empresa.append("rg", this.empresa.rg);
        empresa.append("ramo_negocio", this.empresa.ramo_negocio);
        empresa.append("instagram", this.empresa.instagram);
        empresa.append("facebook", this.empresa.facebook);
        empresa.append("linkedin", this.empresa.linkedin);
        empresa.append("youtube", this.empresa.youtube);
        empresa.append("endereco", this.empresa.endereco);
        empresa.append("numero", this.empresa.numero);
        empresa.append("bairro", this.empresa.bairro);
        empresa.append("complemento", this.empresa.complemento);
        empresa.append("cep", this.empresa.cep);
        empresa.append("uf", this.empresa.uf);
        empresa.append("cidade", this.empresa.cidade);
        empresa.append("celular", this.empresa.celular);
        empresa.append("whatsapp", this.empresa.whatsapp);
        empresa.append("telegram", this.empresa.telegram);
        empresa.append("email", this.empresa.email);

        postEmpresa(empresa)
          .then((response) => {
            if (response.status === 201) {
              this.$toast.success("Empresa adicionada com sucesso!");
              this.$router.push({ path: "/gestor/empresa" });
              this.loading = false;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  },

  mounted() {},
};
</script>

<style lang="scss" scoped>
.hoverFile {
  border-color: #2196f3 !important;
  color: #2196f3 !important;
}

#dropzone {
  border: 2px dashed #dddddd;
  padding: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all ease-in-out 0.3s;

  &:hover {
    border-color: #2196f3;
    color: #2196f3;
  }

  ::v-deep .dz-preview {
    display: none !important;
  }
}
</style>
